.font-sq {
  font-family: Haffer SQ XH,ui-sans-serif,system-ui;
}

.font-semibold {
  font-weight: 600;
}

.list-scroll-y::-webkit-scrollbar {
    width: 5px;
}

.list-scroll-y::-webkit-scrollbar-thumb {
    min-height: 96px;
    border-radius: 20px;
    background-color: rgba(142,159,224,.8);
}

.list-scroll-y::-webkit-scrollbar-track {
    background-color: transparent;
}

.dark .home-tab-default-bg {
    background-color: rgba(78,96,174,.12);
}

.dark .ant-progress .ant-progress-text, .dark .ant-progress-circle .ant-progress-text {
    color: #fff;
}