.page-wrapper {
  display: flex;
  width: 100%;
  max-width: 1200px;
  flex-direction: column;
  border-left-width: 1px;
  --tw-border-opacity: 1;
  /* light */
  /* border-left-color: rgb(218 227 255/var(--tw-border-opacity)); */
  font-family: Haffer SQ XH,ui-sans-serif,system-ui;
  border-left-color: rgba(110,129,203,.25);
}